import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Session from 'react-session-api'
import cryptoJs from 'crypto-js';
import logo from '../Assets/WFMLogo.png'
import { Alert, Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Snackbar, Switch } from '@mui/material';
import { useSession } from '../Provider/Session';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="#">
            Dapis
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login(props) {
    const session = useSession()
    const token = session.token
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [client, setClient] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [changeSession, setChangeSession] = useState(false)
    const [tmpId, setTmpID] = useState(0)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [forgotPassword, setForgotPassword] = useState(false)
    const [recoveryData, setRecoveryData] = useState('')
    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'success',
        message: ''
    })
    const [waiting, setWaiting] = useState(false)
    const [alertForgot, setAlertForgot] = useState({
        open: false, message: ''
    })
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event) => {
        if (event){
            event.preventDefault()
        }
        const formData = new FormData();
        formData.append('username', username)
        formData.append('password', password)
        if(token){
            formData.append('token', token)
        }
        formData.append('type', 'web')

        if(client){
            formData.append('client', client)
        }

        setLoading(true)

        var {data} = await session.login(formData)

        setLoading(false)
        if (data.success){
            Session.set('session', cryptoJs.AES.encrypt(JSON.stringify(data.data), 'service').toString() )
            session.setSession(data.data)
            axios.defaults.headers.common = {
                "X-API-Key": data.data.key,
            }
        } else {
            if ( data.id ){
                setChangeSession(true)
                setTmpID(data.id)
            } else {
                setError(true)
                setMessage(data.error)
            }
        }
    };

    const togglePassword = () => {
        setPasswordVisible(!passwordVisible)
    }

    const handleChange = async () => {
        setChangeSession(false)
        setLoading(true)
        var formData = new FormData()
        formData.append('id', tmpId)
        formData.append('type', 'web')
        if (client) formData.append('client', true)
        await axios.post( session.apiUrl() + 'logout', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })

        handleSubmit()
    }

    const handleRecovery = () => {
        setWaiting(true)
        setForgotPassword(false)
        var formData = new FormData();
        formData.append('data', recoveryData)
        axios.post(session.apiUrl() + 'forgotpassword', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then( ({data}) => {
            setWaiting(false)
            if (data.success === 1 ){
                setSnackbar({
                    open: true, severity: 'success', message: data.data
                })
            } else {
                setForgotPassword(true)
                setAlertForgot({
                    open: true, message: data.error
                })
            }
        })
    }

    const closeSnackbar = () => {
        setSnackbar({
            open: false, severity: 'success', message: ''
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ width: '60%' }} alt='' src={logo} />
                    <Typography component="h1" variant="h5">
                        Inicio de sesión
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            value={username}
                            onChange={(event) => {setUsername(event.target.value)}}
                            fullWidth
                            id="username"
                            label="Nombre de usuario"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={(event) => {setPassword(event.target.value)}}
                            name="password"
                            label="Contraseña"
                            type={ passwordVisible ? 'text' : 'password' }
                            id="password"
                            InputProps={{
                                endAdornment: 
                                    <InputAdornment position="end">
                                        <IconButton onClick={togglePassword}>
                                            { passwordVisible ? <VisibilityOff /> : <Visibility /> }
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/*<Link onClick={handleForgot} href='#' underline='none'>Olvide mi contraseña</Link>*/}
                            <Box></Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>
                                    ¿Soy cliente?
                                </Typography>
                                <Switch checked={client} onChange={(event) => {setClient(event.target.checked)}} />
                            </Box>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                        {
                            loading ?
                            <CircularProgress /> :
                            'Iniciar sesión'
                        }
                        </Button>
                    </Box>
                    {
                        error && (
                            <Alert onClose={() => {setError(false)}} sx={{ width: '100%' }} severity='error' variant='filled'>{ message }</Alert>
                        )
                    }
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            {
                changeSession && (
                    <Dialog open={changeSession} onClose={() => {setChangeSession(false)}}>
                        <DialogTitle>
                            Sessión en otro dispositivo
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                El usuario que trata de ingresar ya tiene una sesión iniciada en otro dispositivo
                            </DialogContentText>
                            <DialogContentText>
                                ¿Desea cerrar la sesión en el otro dispositivo e inicar en este nuevo?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='error' size='small' onClick={() => {setChangeSession(false)}}>
                                Cancelar
                            </Button>
                            <Button variant='contained' color='primary' size='small' onClick={handleChange}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                forgotPassword && (
                    <Dialog open={forgotPassword} onClose={() => {setForgotPassword(false)}}>
                        <DialogTitle>
                            Recuperar contraseña
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ingresa tu nombre usuario registrado en la plataforma.
                            </DialogContentText>
                            <DialogContentText>
                                Se le enviará al administrador una solicitud de recuperación de contraseña.
                            </DialogContentText>
                            <TextField
                                label='Nombre de usuario'
                                value={recoveryData}
                                onChange={(event) => {setRecoveryData(event.target.value)}}
                                size='small'
                                margin='normal'
                                fullWidth
                            />
                            {
                                alertForgot.open && (
                                    <Alert severity='error' >{alertForgot.message}</Alert>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' size='small' color='error' onClick={() => {setForgotPassword(false)}}>
                                Cancelar
                            </Button>
                            <Button disabled={!Boolean(recoveryData)} variant='contained' size='small' color='primary' onClick={handleRecovery}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert severity={Snackbar.severity} >{snackbar.message}</Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
                onClick={() => {setWaiting(false)}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </ThemeProvider>
    );
}