import React, { createContext, useContext, useState } from "react";
import Session from 'react-session-api'
import cryptoJs from 'crypto-js'
import { fetchToken } from '../firebase';
import axios from "axios";

Session.config(true, 120)

const SessionContext = createContext(null)

export const SessionProvider = ({children}) => {
    const [session, setSession] = useState(
        Session.get('session') ?
        JSON.parse(cryptoJs.AES.decrypt(Session.get('session'), 'service').toString(cryptoJs.enc.Utf8)) :
        null
    )
	const [isTokenFound, setTokenFound] = useState(false);
	const [token, setToken] = useState(null)
	fetchToken(setTokenFound, setToken);

    const apiUrl = () => {
        //return 'https://prueba.workflowmanager.me/apiwfm/v2/'
        return 'https://empire.workflowmanager.me/apiwfm/v2/'
        //return 'https://isi.workflowmanager.me/apiwfm/v2/'
    }

    const serverUrl = () => {
        //return 'https://prueba.workflowmanager.me/apiwfm/'
        return 'https://empire.workflowmanager.me/apiwfm/'
        //return 'https://isi.workflowmanager.me/apiwfm/'
    }

    const login = async (form) => {
        return await axios.post(apiUrl() + 'login', form, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    }

    const logout = () => {
        var formData = new FormData()
        formData.append('id', session.id)
        formData.append('type', 'web')
        if (session.client) formData.append('client', 1);
        axios.post( apiUrl() + 'logout', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then( ({data}) => {
            Session.remove('session')
            window.location.replace('/')
        })
    }

    const getAuth = () => {
        if (Session.get('session')){
            var bytes  = cryptoJs.AES.decrypt(Session.get('session'), 'service');
            var decryptedData = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));
            //setUser(decryptedData)
            return decryptedData
        } else {
            return null
        }
    }

    const getPermission = () => {
        var permission = session?.rol?.permission ?? []
        var userPermission = session?.permission ?? []

        return ({...permission, ...userPermission})
    }

    const isClient = () => {
        if (Session.get('session')){
            var bytes  = cryptoJs.AES.decrypt(Session.get('session'), 'service');
            var decryptedData = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));

            if ('client' in decryptedData){
                return true
            }
        }
        return false
    }

    return <SessionContext.Provider value={{session, token, login, logout, getAuth, getPermission, apiUrl, serverUrl, isClient, setSession}} >{children}</SessionContext.Provider>
}

export const useSession = () => {
    return useContext(SessionContext)
}